<template>
  <div class="modal" v-if="isActive">
    <div class="overlay" @click="closePopup"></div>
    <div class="delete-popup-container" ref="popupContainer">
      <div class="popup-header">
        <span class="title2" style="margin-bottom: 12px;">
          {{ isSuccess ? '구독이 시작됐어요' : '결제하지 못했어요' }}
        </span>
        <span class="text2" style="margin-bottom: 20px;">
          {{ isSuccess ? '나와 가장 잘 맞는 메이트와\n대화를 나눠보세요!' : '결제 수단 확인 후 다시 시도해 주세요' }}
        </span>
      </div>
      <div class="popup-content">
        <img v-show="isSuccess" src="@/paywall/assets/clover-success.jpg" alt="loading">
        <img v-show="!isSuccess" src="@/paywall/assets/clover-failed.jpg" alt="loading">
      </div>
      <div class="popup-footer">
        <div
            class="btn2"
            @click="$emit('close')"
            :style="buttonStyle">
          {{ isSuccess ? '확인' : '확인' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscriptionResultPopup",
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  computed: {
    buttonStyle() {
      return {
        flex: 1,
        background: this.isSuccess ? 'var(--Color-palette-Primary-Default)' : 'var(--Color-palette-Primary-Default)',
        color: this.isSuccess ? 'var(--Grayscale-Gray12)' : 'var(--Grayscale-Gray12)'
      }
    }
  }
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999;
}

.delete-popup-container {
  position: fixed;
  top: 50%; /* 화면의 수직 중앙에 위치하도록 설정 */
  left: 50%; /* 화면의 수평 중앙에 위치하도록 설정 */
  transform: translate(-50%, -50%); /* 팝업의 중심을 화면 중심에 정확히 맞추기 위해 */
  width: calc( 100vw - 40px);
  padding: 52px 0 30px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 100000;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.popup-header{
  display: flex;
  flex-direction: column;
}

.popup-footer{
  display: flex;
  flex-direction: row;
  width: calc(100% - 32px);
  gap: 8px;
  margin: 50px 16px 0 16px;
  justify-content: space-between;
}

.popup-content{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.popup-content img{
  width: 50%;
  height: auto;
}

.btn2{
  width: 100%;
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  background-color: var(--Grayscale-Gray10);
  color: var(--Grayscale-Gray6);
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
}


</style>