<template>
  <div v-if="isVisible" class="webview-overlay">
    <div class="webview-container">
      <div class="webview-header">
        <button @click="close" class="close-button">✕</button>
      </div>
      <iframe
          :src="url"
          class="webview-iframe"
          frameborder="0"
          sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebViewOverlay',
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.webview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.webview-container {
  position: relative;
  width: 90%;
  height: 80%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.webview-header {
  position: absolute;
  width: 100%;
  z-index: 9999;
  background-color: white;
  top: 0;
  padding: 16px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.webview-iframe {
  flex-grow: 1;
  width: 100%;
  border: none;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  overscroll-behavior: none;
}
</style>