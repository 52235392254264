<template>
  <div class="modal-overlay">
    <Loading v-model:active="isLoading"
             loader="spinner"
             color="#1E2352"
             :opacity="0.7"
             :z-index="99999">
    </Loading>
    <Loading v-model:active="isImageLoading"
             loader="dots"
             color="#1E2352"
             :opacity="1"
             :z-index="9999">
      <template v-slot:default>
        <div class="custom-loader">
          <img :src="require('@/avatar/assets/images/main-loading/logo.svg')" alt="loader" class="loader-img"/>
          <img :src="require('@/avatar/assets/images/main-loading/moving.svg')" alt="moving" class="moving-svg"/>
        </div>
      </template>
    </Loading>
    <WebViewOverlay
        :isVisible="isWebViewVisible"
        :url="webViewUrl"
        @close="closeWebView"
    />
    <SubscriptionResultPopup
        :is-active="isResultPopupActive"
        :is-success="isSuccess"
        @close="isResultPopupActive = false"/>
    <div class="modal-container">
      <div class="modal-content">
        <div class="modal-header">
          <div class="title4 gray8" @click="handleClose">닫기</div>
        </div>
        <img src="@/paywall/assets/sub-paywall.jpg" alt="sub-paywall">

        <div class="scrollable-content">
          <div class="select-box featured"
               v-wave
               @click="selectPlan('yearly')"
               v-if="subscriptionPrices && subscriptionPrices.yearly">
            <div class="best-tag">BEST</div>
            <div class="texts">
              <div class="heading-texts">
                <div class="title2" style="color:var(--Color-palette-Primary-Default)">연 {{formatNumber(subscriptionPrices.yearly.price)}}원</div>
                <div class="text4 gray8">(월 1회 정기 결제/월 {{formatNumber(subscriptionPrices.yearly.price/12)}}원)</div>
              </div>
              <div class="body-texts">
                <div class="text3" style="color:var(--Color-palette-Primary-Lighten1)">1년간 무제한 대화 + 타로챗 맞춤 점술</div>
              </div>
            </div>
            <div class="select">
              <img :src="require('@/paywall/assets/selected.svg')" alt="selected" class="selected-icon" v-if="selectedPlan === 'yearly'">
              <img :src="require('@/paywall/assets/deselected.svg')" alt="unselected" class="selected-icon" v-else>
            </div>
          </div>
          <div class="select-box"
               v-wave
               @click="selectPlan('monthly')"
               v-if="subscriptionPrices && subscriptionPrices.monthly">
            <div class="texts">
              <div class="heading-texts">
                <div class="title2" style="color:var(--Color-palette-Primary-Default)">월 {{formatNumber(subscriptionPrices.monthly.price)}}원</div>
                <div class="text4 gray8">(월 1회 정기 결제)</div>
              </div>
              <div class="body-texts">
                <div class="text3 gray6">1개월간 무제한 대화 + 타로챗 맞춤 점술</div>
              </div>
            </div>
            <div class="select">
              <img :src="require('@/paywall/assets/selected.svg')" alt="selected" class="selected-icon" v-if="selectedPlan === 'monthly'">
              <img :src="require('@/paywall/assets/deselected.svg')" alt="unselected" class="selected-icon" v-else>
            </div>
          </div>
        </div>
        <div class="disclaimer">
          <div class="aa" @click="openWebView('https://terms.1zlabs.com/mytarot_service')"/>
          <div class="bb" @click="openWebView('https://terms.1zlabs.com/mytarot_privacy')"/>
          <div class="cc" @click="openWebView('https://terms.1zlabs.com/refund')"/>
          <img id="disclaimer" :src="require('@/paywall/assets/disclaimer.png')">
        </div>
      </div>
      <div class="cta">
        <button
            class="button-primary"
            :class="{ 'button-disabled': !selectedPlan }"
            @click="requestAppPurchase"
            :disabled="!selectedPlan">대화 시작하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import {getTarotUserInfo} from "@/tarot_box/helper/mytarot_api";
import {remoteConfig} from "@/common/firebase";
import {mapActions, mapGetters} from "vuex";
import SubscriptionResultPopup from "@/paywall/component/SubscriptionResultPopup.vue";
import Loading from "vue-loading-overlay";
import WebViewOverlay from "@/my_page/components/WebViewOverlay.vue";
import {logEvent} from "@/avatar/helper/native_api";

export default {
  name: "MateChatSubscriptionPaywall",
  inject: ['appListener', 'appCommander'],
  components: {WebViewOverlay, Loading, SubscriptionResultPopup},
  data() {
    return {
      userId: 0,
      userPhoneNumber: '',
      osType: '',
      selectedPlan: 'yearly',
      isResultPopupActive: false,
      subscribeSubscription: null,
      isSuccess: false,
      isImageLoading: true,
      isLoading: false,
      isWebViewVisible: false,
    };
  },
  props: {
    avatarIndex: {
      type: Number,
      required: true
    },
    avatarId: {
      type: Number,
      required: true
    }
  },
  async mounted() {
    await this.getPaywallInfo();
    const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
    this.userId = parseInt(urlParams.get('tarot_user_id'));
    this.userPhoneNumber = urlParams.get('phone_number');
    this.osType = urlParams.get('os_type');
    this.getUserData();
    setTimeout(() => {
      this.isImageLoading = false;
    }, 1000);
  },
  unmounted() {
    this.subscribeSubscription?.unsubscribe();
  },
  computed: {
    ...mapGetters(['getSubscriptionPrices']),
    subscriptionPrices() {
      const osType = this.osType === 'aos' ? 'android' : this.osType;
      return this.getSubscriptionPrices(osType, this.avatarIndex);
    }
  },
  methods:{
    logEvent,
    ...mapActions(['updateSubscriptionPaywall']),
    formatNumber(num) {
      const integerPart = Math.floor(num);
      return integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async getPaywallInfo() {
      const configValue = remoteConfig.getValue('subscription_product_id_list');
      const parsedValue = JSON.parse(configValue.asString());

      const subscriptionPaywall = {
        android: parsedValue.filter(item => item.platform === 'android'),
        ios: parsedValue.filter(item => item.platform === 'ios')
      };

      await this.updateSubscriptionPaywall(subscriptionPaywall);
    },
    async getUserData() {
      let res = await getTarotUserInfo(this.userId, this.userPhoneNumber);
      if (res.result === 'success') {
        this.userClover = res.data.clover_balance;
      }
    },
    selectPlan(plan) {
      this.selectedPlan = this.selectedPlan === plan ? null : plan;
      if(this.selectedPlan !== null){
        logEvent('matechat_paywall_click', {'product_id': this.selectedPlan})
      }
    },
    requestAppPurchase() {
      if (!this.selectedPlan) {
        return;
      }
      logEvent('matechat_paywall_purchase', {})
      this.isLoading = true;
      const selectedProduct = this.subscriptionPrices[this.selectedPlan];
      this.subscribeSubscription?.unsubscribe();
      this.subscribeSubscription = this.appListener.subscribeSingleAction('subscription_result', (data) => {
        if (data.result === 'success') {
          logEvent('matechat_paywall_success_popup', {})
          this.handleSuccessfulSubscription();
          setTimeout (() => {
            this.isLoading = false;
            this.isResultPopupActive = false;
          }, 1000);
        } else if (data.result === 'cancel') {
          logEvent('matechat_paywall_fail_popup', {})
          this.isSuccess = false;
          this.isLoading = false;
        } else {
          logEvent('matechat_paywall_fail_popup', {})
          this.isSuccess = false;
          this.isResultPopupActive = true;
        }
        this.isLoading = false;
      });
      const avatarIdNum = parseInt(this.avatarId);
      this.appCommander.callAppAction(`request_matechat_subscription`, { productId: selectedProduct.product_id, avatarId: avatarIdNum });
      console.log(`Selected plan: ${this.selectedPlan}, AvatarId: ${avatarIdNum}, Product ID: ${selectedProduct.product_id}, Price: ${selectedProduct.price}`);
    },
    handleSuccessfulSubscription() {
      this.$emit('close');
      console.log('Subscription success');
    },
    closePopup() {
      this.isResultPopupActive = false;
    },
    openWebView(url) {
      this.webViewUrl = url;
      this.isWebViewVisible = true;
    },
    closeWebView() {
      this.isWebViewVisible = false;
    },
    handleClose() {
      logEvent('matechat_paywall_close', {});
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

:deep(.modal-container){
  font-family: "SUIT-Variable", sans-serif
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 9999;
}

.modal-container {
  position: relative;
  background-color: #fff;
  width: 100%;
  max-width: 480px;
  height: 78vh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal-container #disclaimer{
  margin-top: 40px;
  width: calc(100% - 40px);
}

.modal-header {
  position: absolute;
  top: 0px;
  right: 5px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 15px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  z-index: 200;
}

.modal-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 40px 12px 0 12px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.scrollable-content {
  width: 100%;
  padding-bottom: 20px;
}

.modal-content img {
  width: 100%;
  object-fit: contain;
}

.select-box {
  position:relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 20px;
  box-sizing: border-box;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #D9D9D9;
}

.best-tag{
  position: absolute;
  top: 0;
  right: 24px;
  border-radius: 0 0 4px 4px;
  background-color: var(--Color-palette-Primary-Lighten2);
  padding: 2px 10px;
  font-size: 16px;
  color: white;
}

.select-box.featured {
  border: 1px solid #BFC5FC;
  background: #F9F9FF;
}

.texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}

.text4 {
  font-size: 10px;
}

.heading-texts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.cta {
  padding: 12px 12px 24px 12px;
  background-color: #fff;
}

.button-primary {
  width: 100%;
  height: 52px;
  background: var(--Color-palette-Primary-Default);
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.button-disabled {
  background-color: var(--Grayscale-Gray8);
  color: var(--Grayscale-Gray12);
  cursor: not-allowed;
}

.disclaimer{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.aa{
  position: absolute;
  top: 38px;
  left: 5%;
  width: 100px;
  height: 20px;
  background: transparent;
}

.bb{
  position: absolute;
  top: 38px;
  left: 35%;
  width: 100px;
  height: 20px;
  background: transparent;
  }

.cc{
  position: absolute;
  top: 38px;
  left: 65%;
  width: 100px;
  height: 20px;
  background: transparent;
}
</style>
